import { Injectable } from '@angular/core';
import { ErrorContent } from '@cloud/logs';

@Injectable({
  providedIn: 'root'
})
export class LogsMapperService {

  mapErrorContentToIDs(errors: ErrorContent[]): string[] {
    return errors.map(err => err.message.id);
  }

}
