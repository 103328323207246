import {NgModule} from '@angular/core';
import {MenuComponent} from "./components/menu/menu.component";
import {AlertComponent} from "./components/alert/alert.component";
import {LoaderComponent} from "./components/loader/loader.component";
import {PolicyComponent} from "./components/policy/policy.component";
import {ModalComponent} from "./components/modal/modal.component";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {RolesSelectorComponent} from "./components/roles-selector/roles-selector.component";
import {MainPageLoaderComponent} from './components/main-page-loader/main-page-loader.component';
import {SearchComponent} from "./components/search/search.component";
import {
  StationPatternDropdownComponent
} from "./components/station-pattern-dropdown/station-pattern-dropdown.component";
import {FilterComponent} from "./components/filter/filter.component";
import {DependenciesModule} from "../core/dependencies.module";
import {SpecificationMenuComponent} from "./components/specification-menu/specification-menu.component";
import {ColumnComponent} from "./components/specification-menu/column/column.component";
import {MetricsColumnComponent} from "./components/specification-menu/metrics-column/metrics-column.component";
import {SearchSpecificationComponent} from './components/search-specification/search-specification.component';
import {MarkLettersPipe, SafePipe, SearchPipe} from "@cloud/shared";
import {DetailsColumnComponent} from './components/specification-menu/details-column/details-column.component';
import {
  SpecificationInfoComponent
} from './components/specification-menu/specification-info/specification-info.component';
import {MetricsSelectorComponent} from './components/specification-menu/metrics-selector/metrics-selector.component';
import {DetailsViewerComponent} from './components/specification-menu/details-viewer/details-viewer.component';
import {AddressAutocompleteComponent} from './components/address-autocomplete/address-autocomplete.component';
import {WarningModalComponent} from './components/warning-modal/warning-modal.component';
import {DatepickerComponent} from "./components/datepicker/datepicker.component";
import {StationTreeComponent} from "./components/station-tree/station-tree.component";


@NgModule({
  declarations: [
    MenuComponent,
    AlertComponent,
    LoaderComponent,
    PolicyComponent,
    ModalComponent,
    SafePipe,
    RolesSelectorComponent,
    MainPageLoaderComponent,
    SearchComponent,
    StationPatternDropdownComponent,
    FilterComponent,
    MarkLettersPipe,
    SearchPipe,
    SpecificationMenuComponent,
    ColumnComponent,
    MetricsColumnComponent,
    SearchSpecificationComponent,
    DetailsColumnComponent,
    SpecificationInfoComponent,
    MetricsSelectorComponent,
    DetailsViewerComponent,
    AddressAutocompleteComponent,
    WarningModalComponent,
    DatepickerComponent,
    StationTreeComponent
  ],
  imports: [
    CommonModule,
    DependenciesModule,
    RouterModule
  ],
  exports: [
    MenuComponent,
    AlertComponent,
    LoaderComponent,
    PolicyComponent,
    ModalComponent,
    SafePipe,
    RolesSelectorComponent,
    MainPageLoaderComponent,
    SearchComponent,
    StationPatternDropdownComponent,
    FilterComponent,
    SpecificationMenuComponent,
    ColumnComponent,
    MetricsColumnComponent,
    SearchSpecificationComponent,
    MetricsSelectorComponent,
    DetailsViewerComponent,
    AddressAutocompleteComponent,
    DatepickerComponent,
    StationTreeComponent
  ]
})

export class UiModule {
}
