export enum AlertType {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
  dark = 'dark'
}

export enum DropdownPlacement {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}

export enum SpecLevels {
  ALL = 'ALL',
  NODE = 'NODE',
  MODULE = 'MODULE',
  DEVICE = 'DEVICE',
  SENSOR = 'SENSOR'
}
export enum ABTypes {
  ALTABIO_3 = 'Alta Bio 3',
  ALTABIO_3_PLUS = 'Alta Bio 3 plus',
  ALTABIO_3_OR = 'Alta Bio 3 or',
  ALTABIO_3_OR_PLUS = 'Alta Bio 3 or plus',
  ALTABIO_5_H = 'Alta Bio 5 H',
  ALTABIO_5_PLUS = 'Alta Bio 5 plus',
  ALTABIO_5_OR = 'Alta Bio 5 or',
  ALTABIO_5_OR_PLUS = 'Alta Bio 5 or plus',
  ALTABIO_5_LOW_H = 'Alta Bio 5 low H',
  ALTABIO_5_LOW_H_PLUS = 'Alta Bio 5 low H plus',
  ALTABIO_7 = 'Alta Bio 7',
  ALTABIO_7_PLUS = 'Alta Bio 7 plus',
  ALTABIO_7_OR = 'Alta Bio 7 or',
  ALTABIO_7_UV_PLUS = 'Alta Bio 7 uv plus',
  ALTABIO_10 = 'Alta Bio 10',
  ALTABIO_10_H_PLUS = 'Alta Bio 10 H plus',
  ALTABIO_10_UV_PLUS = 'Alta Bio 10 uv plus',
  ALTABIO_15_H = 'Alta Bio 15 H',
  ALTABIO_15_H_PLUS = 'Alta Bio 15 H plus'
}

export enum StType {
  ALTABIO = 'ALTABIO',
  PRO = 'PRO'
}

export enum CloudUserType {
  individualPerson = 'individual-person',
  organization = 'company',
  maintainer = 'dealer',
}

export enum SearchTypes {
  STATIONS = 'stations',
  USERS = 'users',
  SPECIFICATIONS = 'specifications'
}

