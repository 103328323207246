import { Injectable } from '@angular/core';
import {StationService} from "@cloud/shared";


@Injectable({
  providedIn: 'root'
})
export class TimestampService {

  private _timeOffset = "+0000";

  constructor(stationService: StationService) {
    stationService.selectedStationSubscriber$.subscribe(st => {
      if (st?.id) this.timeOffset = this.detectTimeZone(st.address?.regionCode ?? null);
    });
  }

  get timeOffset() {
    return this._timeOffset;
  }

  set timeOffset(val: string) {
    this._timeOffset = val;
  }

  detectTimeZone(timeZone: string | null) {
    switch(timeZone) {
      case '3900000000000':
        return '+0200';
      case '0100000000000':
      case '0500000000000':
      case '0600000000000':
      case '0700000000000':
      case '0800000000000':
      case '0900000000000':
      case '1000000000000':
      case '1100000000000':
      case '1200000000000':
      case '1300000000000':
      case '1500000000000':
      case '1600000000000':
      case '2000000000000':
      case '2100000000000':
      case '2300000000000':
      case '2600000000000':
      case '2900000000000':
      case '3100000000000':
      case '3200000000000':
      case '3300000000000':
      case '3400000000000':
      case '3500000000000':
      case '3600000000000':
      case '3700000000000':
      case '4000000000000':
      case '4300000000000':
      case '4400000000000':
      case '4600000000000':
      case '4700000000000':
      case '4800000000000':
      case '5000000000000':
      case '5100000000000':
      case '5200000000000':
      case '5300000000000':
      case '5700000000000':
      case '5800000000000':
      case '6000000000000':
      case '6100000000000':
      case '6200000000000':
      case '6700000000000':
      case '6800000000000':
      case '6900000000000':
      case '7100000000000':
      case '7600000000000':
      case '7700000000000':
      case '7800000000000':
      case '8300000000000':
      case '9100000000000':
      case '9200000000000':
        return '+0300';
      case '1800000000000':
      case '3000000000000':
      case '6300000000000':
      case '6400000000000':
      case '7300000000000':
        return '+0400';
      case '0200000000000':
      case '4500000000000':
      case '5600000000000':
      case '5900000000000':
      case '6600000000000':
      case '7200000000000':
      case '7400000000000':
      case '8600000000000':
      case '8900000000000':
      case '9900000000000':
        return '+0500';
      case '5500000000000':
        return '+0600';
      case '0400000000000':
      case '1700000000000':
      case '1900000000000':
      case '2200000000000':
      case '2400000000000':
      case '4200000000000':
      case '5400000000000':
      case '7000000000000':
        return '+0700';
      case '0300000000000':
      case '3800000000000':
        return '+0800';
      case '1400000000000':
      case '2800000000000':
      case '7500000000000':
        return '+0900';
      case '2500000000000':
      case '2700000000000':
      case '7900000000000':
        return '+1000';
      case '4900000000000':
      case '6500000000000':
        return '+1100';
      case '4100000000000':
      case '8700000000000':
        return '+1200';
      default:
        return '+0000';
    }
  }

}
