import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResizeElementService {

  constructor(private zone: NgZone) {}

  observeElementsResize(element?: Element) {
    const size$ = new BehaviorSubject<[number, number]>([0, 0]);
    const sizeObservable = size$.asObservable();

    let resizeObserver: ResizeObserver | null = null;


    if (element) {
      resizeObserver = new ResizeObserver(entries => {
        this.zone.run(() => {
          size$.next([entries[0].contentRect.width, entries[0].contentRect.height]);
        });
      });

      resizeObserver.observe(element);
    }

    return {
      sizeObservable,
      observer: resizeObserver
    };
  }

}
