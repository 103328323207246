import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ErrorCode, ErrorContent, LogsServiceModel, PageLite} from "@cloud/logs";
import {LogsServiceMock} from "./logs-service-mock.service";
import {LogsServiceApi} from "./logs-service-api.service";

@Injectable({
  providedIn: 'root'
})
export class LogsService implements LogsServiceModel {

  constructor(
    private api: LogsServiceApi,
    private mock: LogsServiceMock
  ) {
  }

  createNewErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.api.postErrorCode(errorCode)
  }

  editErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.api.putErrorCode(errorCode)
  }

  getErrorCodes(): Observable<ErrorCode[]> {
    return this.api.getErrorCodes()
  }

  getStationErrors(id: string, count = 3, offset = 0, filters = new Map<string, any>()): Observable<PageLite> {
    return this.api.getLastStationErrors(id, count, offset, filters);
  }

  setSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]> {
    return this.api.patchSelectedErrors(errors);
  }

}
