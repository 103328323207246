import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {ErrorCode, ErrorContent, PageLite} from "@cloud/logs";

@Injectable({
  providedIn: 'root'
})
export class LogsServiceMock {
  public postErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return of(errorCode);
  }

  putErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return of(errorCode);
  }

  getErrorCodes(): Observable<ErrorCode[]> {
    const errorCodes: ErrorCode[] = [
      {
        code: "EA20001",
        level: "WARNING",
        description: "Насос. Холостой ход.",
        full_description: "Потребляемый ток одного из насосов меньше минимального.",
        recommendations: "Проверить наличие воды в камере, в которой установлен насос."
      },
      {
        code: "EA30004",
        level: "WARNING",
        description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        full_description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        recommendations: "При первом появлении - проверить режим работы подающей КНС (правильный режим - Тайм КНС), проверить правильность установки интервалов работы и паузы для насосов подающей КНС. При регулярном повторном появлении - обратиться к производителю очиистных сооружений."
      },
      {
        code: "E200012",
        level: "WARNING",
        description: "DOT, перегрузка.",
        full_description: "Превышение пользовательского порогового значения тока одного из каналов нагрузки модуля коммутации силовой нагрузки.",
        recommendations: "Отключить питание DOT. Проверить состояние насосов: наличие посторонних предметов в рабочей камере, состояние запорной арматуры."
      },
      {
        code: "EA20001",
        level: "WARNING",
        description: "Насос. Холостой ход.",
        full_description: "Потребляемый ток одного из насосов меньше минимального.",
        recommendations: "Проверить наличие воды в камере, в которой установлен насос."
      },
      {
        code: "EA30004",
        level: "WARNING",
        description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        full_description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        recommendations: "При первом появлении - проверить режим работы подающей КНС (правильный режим - Тайм КНС), проверить правильность установки интервалов работы и паузы для насосов подающей КНС. При регулярном повторном появлении - обратиться к производителю очиистных сооружений."
      },
      {
        code: "E200012",
        level: "WARNING",
        description: "DOT, перегрузка.",
        full_description: "Превышение пользовательского порогового значения тока одного из каналов нагрузки модуля коммутации силовой нагрузки.",
        recommendations: "Отключить питание DOT. Проверить состояние насосов: наличие посторонних предметов в рабочей камере, состояние запорной арматуры."
      },
      {
        code: "EA20001",
        level: "WARNING",
        description: "Насос. Холостой ход.",
        full_description: "Потребляемый ток одного из насосов меньше минимального.",
        recommendations: "Проверить наличие воды в камере, в которой установлен насос."
      },
      {
        code: "EA20001",
        level: "WARNING",
        description: "Насос. Холостой ход.",
        full_description: "Потребляемый ток одного из насосов меньше минимального.",
        recommendations: "Проверить наличие воды в камере, в которой установлен насос."
      },
      {
        code: "EA30004",
        level: "WARNING",
        description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        full_description: "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
        recommendations: "При первом появлении - проверить режим работы подающей КНС (правильный режим - Тайм КНС), проверить правильность установки интервалов работы и паузы для насосов подающей КНС. При регулярном повторном появлении - обратиться к производителю очиистных сооружений."
      },
      {
        code: "E200012",
        level: "WARNING",
        description: "DOT, перегрузка.",
        full_description: "Превышение пользовательского порогового значения тока одного из каналов нагрузки модуля коммутации силовой нагрузки.",
        recommendations: "Отключить питание DOT. Проверить состояние насосов: наличие посторонних предметов в рабочей камере, состояние запорной арматуры."
      },
      {
        code: "EA20001",
        level: "WARNING",
        description: "Насос. Холостой ход.",
        full_description: "Потребляемый ток одного из насосов меньше минимального.",
        recommendations: "Проверить наличие воды в камере, в которой установлен насос."
      }
    ];
    return of(errorCodes);
  }

  getLastStationErrors(id: string, count: number, offset: number, filters: Map<string, any>): Observable<PageLite> {
    let content: ErrorContent[] = [
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA20001",
          "level": "CRITICAL",
          "description": "Насос. Холостой ход.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30004",
          "level": "WARNING",
          "description": "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E200012",
          "level": "INFO",
          "description": "DOT, перегрузка.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      }, {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": true,
        "message": {
          "id": "123",
          "code": "EA20001",
          "level": "CRITICAL",
          "description": "Насос. Холостой ход.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30004",
          "level": "WARNING",
          "description": "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": true,
        "message": {
          "id": "123",
          "code": "E200012",
          "level": "INFO",
          "description": "DOT, перегрузка.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      }, {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA20001",
          "level": "CRITICAL",
          "description": "Насос. Холостой ход.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E200012",
          "level": "WARNING",
          "description": "DOT, перегрузка",
          "full_description": "Превышение пользовательского порогового значения тока одного из каналов нагрузки модуля коммутации силовой нагрузки.",
          "recommendations": "Отключить питание DOT. Проверить состояние насосов: наличие посторонних предметов в рабочей камере, состояние запорной арматуры.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30002",
          "level": "CRITICAL",
          "description": "Высокая нагрузка на станцию.",
          "full_description": "Расчетный суточный объем сточных вод, поступающий на станцию очистки, превысил паспортное значение.",
          "recommendations": "Проверить герметичность трассы подачи сточных вод на станцию.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30004",
          "level": "CRITICAL",
          "description": "Превышение часового расхода.",
          "full_description": "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
          "recommendations": "При первом появлении - проверить режим работы подающей КНС (правильный режим - Тайм КНС), проверить правильность установки интервалов работы и паузы для насосов подающей КНС. При регулярном повторном появлении - обратиться к производителю очиистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30007",
          "level": "CRITICAL",
          "description": "Среднечасовая температура сточных вод выше нормы.",
          "full_description": "Среднечасовая температура сточных вод больше номинальной.",
          "recommendations": "Обратитесь к производителю очистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E201004",
          "level": "CRITICAL",
          "description": "Разница фазных токов выше уставки максимума.",
          "full_description": "Разница между фазными токами выше максимального значения.",
          "recommendations": "Обрыв одной из питающих устройство фаз. Проверьте целостность кабельной трассы к устройству. При повторном появлении обратитесь к производителю очистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E201004",
          "level": "CRITICAL",
          "description": "Разница фазных токов выше уставки максимума.",
          "full_description": "Разница между фазными токами выше максимального значения.",
          "recommendations": "Обрыв одной из питающих устройство фаз. Проверьте целостность кабельной трассы к устройству. При повторном появлении обратитесь к производителю очистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E201004",
          "level": "CRITICAL",
          "description": "Разница фазных токов выше уставки максимума.",
          "full_description": "Разница между фазными токами выше максимального значения.",
          "recommendations": "Обрыв одной из питающих устройство фаз. Проверьте целостность кабельной трассы к устройству. При повторном появлении обратитесь к производителю очистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E201004",
          "level": "CRITICAL",
          "description": "Разница фазных токов выше уставки максимума.",
          "full_description": "Разница между фазными токами выше максимального значения.",
          "recommendations": "Обрыв одной из питающих устройство фаз. Проверьте целостность кабельной трассы к устройству. При повторном появлении обратитесь к производителю очистных сооружений.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30004",
          "level": "WARNING",
          "description": "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E200012",
          "level": "INFO",
          "description": "DOT, перегрузка.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      }, {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA20001",
          "level": "CRITICAL",
          "description": "Насос. Холостой ход.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "EA30004",
          "level": "WARNING",
          "description": "Объем сточных вод, поступивших на станцию за предыдущий астрономический час, больше номинального.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      },
      {
        "created_at": 1701851493000,
        "updated_at": 1701851493000,
        "is_resolved": false,
        "message": {
          "id": "123",
          "code": "E200012",
          "level": "INFO",
          "description": "DOT, перегрузка.",
          "full_description": "Потребляемый ток одного из насосов меньше минимального.",
          "recommendations": "Проверить наличие воды в камере, в которой установлен насос.",
          "metadata": {
            "station": "Комплекс ЛОС, Рязанская обл., с. Дубровичи",
            "device_id": "2d24f46f-4f4c-4563-8eab-82b5f4ac7709",
            "device_name": "Насос рециркуляции 1"
          }
        }
      }
    ];


    filters.forEach((value, key) => {
      content = content.filter(value1 => {
        if (key === "level") {
          if (value === null) return value1;
          return value1.message.level === value;
        }
        if (key === "is_resolved") {
          if (value === null) return value1;
          return value1.is_resolved === value;
        }
        return value1;
      });
    });

    content = content.slice(0, count);

    const isLast = offset === 1;

    const errors: PageLite = {
      page_number: offset,
      page_size: isLast ? 5 : 10,
      total_pages: 2,
      total_elements: 15,
      last: isLast,
      empty: false,
      content: content
    };

    return of(errors);
  }


}
