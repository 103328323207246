import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ErrorCode, ErrorContent, PageLite} from "@cloud/logs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EnvironmentApiUrlService, TimestampService} from "@cloud/shared";
import {LogsMapperService} from "./logs-mapper.service";

@Injectable({
  providedIn: 'root'
})
export class LogsServiceApi {
  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private timestampService: TimestampService,
              private mapper: LogsMapperService) {
  }

  public getLastStationErrors(id: string, count: number, offset: number, filters: Map<string, any>): Observable<PageLite> {
    let params = new HttpParams();
    params = params.append("count", count);
    params = params.append("offset", offset);
    if (filters.get("level")) params = params.append("level", filters.get("level"));
    if (filters.get("is_resolved") !== undefined) params = params.append("is_resolved", filters.get("is_resolved"));
    return this.http.get<PageLite>(this.envUrl.apiUrlV1 + `/errors/stations/${id}`, {params: params});
  }

  public getErrorCodes(): Observable<ErrorCode[]> {
    return this.http.get<ErrorCode[]>(this.envUrl.apiUrlV1 + `/errors/codes`);
  }

  public putErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.http.put<ErrorCode>(this.envUrl.apiUrlV1 + `/errors/codes/${errorCode.code}`, errorCode);
  }

  public postErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.http.post<ErrorCode>(this.envUrl.apiUrlV1 + `/errors/codes`, errorCode);
  }

  public patchSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]> {
    const params = new HttpParams({fromObject: {status: "resolved"}});

    const ids = this.mapper.mapErrorContentToIDs(errors);

    return this.http.patch<ErrorContent[]>(this.envUrl.apiUrlV1 + `/errors`, ids, {params});

  }

}
